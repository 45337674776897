// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCDVdRJYgF7JHRvbRomqoeWYVMjdruByG0",
    authDomain: "vedic-rashi.firebaseapp.com",
    databaseURL: "https://vedic-rashi-default-rtdb.firebaseio.com",
    projectId: "vedic-rashi",
    storageBucket: "vedic-rashi.firebasestorage.app",
    messagingSenderId: "526646619509",
    appId: "1:526646619509:web:f56d524d5b3ac4b61ff73c",
    measurementId: "G-EHKNT1X608"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();
