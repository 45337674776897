import React from 'react';
import './Shimmer.css'; // Style for shimmer effect

const Shimmer = () => {
    return (
        <div className='loadin-page'>
            {/* Top Section */}
            <div className="loading-page-container">
              <h2>Connecting with the stars...</h2>
              <p>Your personalized horoscope is on its way!</p>
              <div className="blur-balls"></div>
              <video className='loading-video' src='https://cdnl.iconscout.com/lottie/premium/thumb/magic-circle-animated-icon-download-in-lottie-json-gif-static-svg-file-formats--occult-magical-sun-mystic-sign-astrology-pack-entertainment-icons-8542368.mp4' autoPlay loop muted></video>
            </div>
        </div>
    );
};

export default Shimmer;
