import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, googleProvider, firestore } from '../firebase';
import { signInWithPopup } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import './LandingPage.css'; // Import your styles
import Nav from './Nav.js';
import Footer from './Footer.js';
import ZoadicSign from './ZoadicSign.js';

const LandingPage = () => {
  const [user] = useAuthState(auth);
  const [buttonLoading, setButtonLoading] = useState(false); // State for button animation
  const navigate = useNavigate();
  const words = ['life choices', 'career paths', 'finance', 'marriage details'];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000); // Change word every 2 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [words.length]);

  const handleChatNowClick = () => {
    navigate('/chat')
    // if (user) {
    //   navigate('/chat'); // Redirect to chat if authenticated
    // } 
    // else {
    //   navigate('/authentication'); // Redirect to authentication if not authenticated
    // }
  };

  return (
    <div>
      <Nav />
      <section className="hero">
        <div className="hero-content">
          <h1>
            Get answers to all your questions about{' '}
            <span className="changing-word">{words[currentWordIndex]}</span>
          </h1>
          <p>Get personalized astrological readings and guidance based on Vedic astrology.</p>
          <button
            onClick={handleChatNowClick}
            className={`hover-filled-slide-right ${buttonLoading ? 'loading' : ''}`}
            disabled={buttonLoading} // Disable button while loading
          >
            {buttonLoading ? (
              <div className="spinner">
                <div className="spin-dot"></div>
              </div>
            ) : (
              'Start Free Chat'
            )}
          </button><br />
          <Link className = "link-style" to="authentication">Create an account to continue →</Link>
        </div>
        <img src="astro.png" alt="Astrologer" className="hero-image" />
      </section>

      {/* Rest of the landing page */}
      <div className='section-2'>
        <img src='https://png.pngtree.com/png-vector/20220619/ourmid/pngtree-zodiac-icon-vector-illustration-design-png-image_5230956.png' alt='.bg'></img>
        <h1>Ultimate Astrology Platform.</h1>
        <p>Discover personalized Kundali readings, astrology insights, and tailored guidance for life, career, and health</p>
        <div className='card-container'>
          <div className="gradient-card">
            <h2 className="card-title">Kundali Reading</h2>
            <p className="card-content">Unlock personalized insights with detailed Kundali readings, offering guidance on life, relationships, career, and health, based on Vedic astrology.</p>
          </div>
          <div className="gradient-card">
            <h2 className="card-title">Daily horoscope</h2>
            <p className="card-content">Unlock personalized insights with detailed Kundali readings, offering guidance on life, relationships, career, and health, based on Vedic astrology.</p>
          </div>
          <div className="gradient-card">
            <h2 className="card-title">Chat with Astrologer</h2>
            <p className="card-content">Unlock personalized insights with detailed Kundali readings, offering guidance on life, relationships, career, and health, based on Vedic astrology.</p>
          </div>
        </div>
      </div>
      <div className='section-3'>
        <h1>Get Your Horoscope Today</h1>
        <p>Your daily horoscope brings clarity to love, ambition, and wellness, helping you find your best path.</p>
        <ZoadicSign />
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
