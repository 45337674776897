import React from 'react'
import './ZoadicSign.css'
import { useNavigate } from 'react-router-dom';

const ZoadicSign = () => {
    const navigate = useNavigate();

    const handleNavigate = async (sign, selectedDate, selectedOption = 'daily') => {
        try {
            // Construct the route based on the selected inputs
            const formattedDate = selectedDate
                ? new Date(selectedDate).toISOString().split('T')[0] // Format as YYYY-MM-DD
                : new Date().toISOString().split('T')[0];
            // Dynamically build the URL with the selected option (daily, weekly, monthly)
            navigate(`/horoscope/${sign}/${formattedDate}/${selectedOption}-horoscope`);
        } catch (error) {
            console.error("Error navigating to horoscope:", error);
        }
    };
        
    
    return (
        <div className='zoadic-container'>
            
            <div className='zoadic-cards' onClick={() => handleNavigate('Aries')}>
                <img src='https://astroarunpandit.org/wp-content/uploads/2024/05/ARIES.png' alt='sign' />
                <p>( Mar 21 - Apr 19 )</p>
            </div>
            <div className='zoadic-cards' onClick={() => handleNavigate('Taurus')}>
                <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/TAURUS-1.png' alt='sign' />
                <p>( Apr 20 - May 20 )</p>
            </div>
            <div className='zoadic-cards' onClick={() => handleNavigate('Gemini')}>
                <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/GEMINI.png' alt='sign' />
                <p>( May 21 - Jun 20 )</p>
            </div>
            <div className='zoadic-cards' onClick={() => handleNavigate('Cancer')}>
                <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/CANCER-1.png' alt='sign' />
                <p>( Jun 21 - Jul 22 )</p>
            </div>
            <div className='zoadic-cards' onClick={() => handleNavigate('Leo')}>
                <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/LEO-1.png' alt='sign' />
                <p>( Jul 23 - Aug 22 )</p>
            </div>
            <div className='zoadic-cards' onClick={() => handleNavigate('Virgo')}>
                <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/VIRGO-1.png' alt='sign' />
                <p>( Aug 23 - Sep 22 )</p>
            </div>
            <div className='zoadic-cards' onClick={() => handleNavigate('Libra')}>
                <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/LIBRA-1.png' alt='sign' />
                <p>( Sep 22 - Oct 23 )</p>
            </div>
            <div className='zoadic-cards' onClick={() => handleNavigate('Scorpio')}>
                <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/SCORPIO-1.png' alt='sign' />
                <p>( Oct 23 - Nov 21 )</p>
            </div>
            <div className='zoadic-cards' onClick={() => handleNavigate('Sagittarius')}>
                <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/SAGITTARIUS.png' alt='sign' />
                <p>( Nov 22 - Dec 21 )</p>
            </div>
            <div className='zoadic-cards' onClick={() => handleNavigate('Capricorn')}>
                <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/CAPRI-1.png' alt='sign' />
                <p>( Dec 22 - Jan 19 )</p>
            </div>
            <div className='zoadic-cards' onClick={() => handleNavigate('Aquarius')}>
                <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/AQUARI-1.png' alt='sign' />
                <p>( Mar21 - Apr19 )</p>
            </div>
            <div className='zoadic-cards' onClick={() => handleNavigate('Pisces')}>
                <img src='https://astroarunpandit.org/wp-content/uploads/2024/04/PISCES-1.png' alt='sign' />
                <p>( Mar21 - Apr19 )</p>
            </div>
        </div>
    )
}

export default ZoadicSign
