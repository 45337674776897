import React, { useEffect, useState } from 'react';
import { useSearchParams, useParams, useNavigate  } from 'react-router-dom';
import axios from 'axios';
import Shimmer from './Shimmer'; // Shimmer component
import './Horoscope.css';
import appConfig from '../config/appConfig.json';
import Nav from './Nav';
import Footer from './Footer';

const Horoscope = () => {
    const { sign, date, selectedOption } = useParams(); // Get the zodiac sign, date, and selectedOption from the URL params
    const navigate = useNavigate(); // Used to navigate programmatically
    const [horoscope, setHoroscope] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentOption, setCurrentOption] = useState(selectedOption || 'daily'); // Default to 'daily'
    const [currentDate, setCurrentDate] = useState(date || new Date().toISOString().split('T')[0]);

    useEffect(() => {
        const fetchHoroscope = async () => {
            if (!sign || !currentOption) return;

            setLoading(true);
            try {
                const endpoint = `/horoscope/get-${currentOption}-horoscope`; // Dynamic endpoint based on selected option
                const response = await axios.post(
                    `${appConfig.REACT_APP_BACKEND_URL}${endpoint}`,
                    { sign, date: currentDate }, // Send the parameters
                    { headers: { 'Content-Type': 'application/json' } }
                );
                setHoroscope(response.data.horoscope);
            } catch (error) {
                console.error(`Error fetching ${currentOption} horoscope:`, error);
            } finally {
                setLoading(false);
            }
        };

        fetchHoroscope();
    }, [sign, currentOption, currentDate]); // Refetch data when `sign` or `selectedOption` changes
    
    useEffect(() => {
        // Update the URL if the selectedOption or date changes
        if (currentOption !== selectedOption || currentDate !== date) {
            navigate(`/horoscope/${sign}/${currentDate}/${currentOption}-horoscope`);
        }
    }, [currentOption, currentDate, navigate, sign, date, selectedOption]);
    const redirectChat = () =>{
        navigate('/chat')
    }
    if (loading) {
        return (
            <>
                <Nav />
                <Shimmer />
            </>
        );
    }

    if (!horoscope) {
        return <p>No horoscope available.</p>;
    }

    const renderHoroscopeContent = () => {
        if (currentOption === 'daily') {
            const { date, summary, personalLife, career, health, finances, advice } = horoscope;
            return (
                <>
                    <p className="date">Date: {date}</p>
                    <div className="horoscope-section">
                        <h3>Summary (Saaransh)</h3>
                        <p>{summary}</p>
                    </div>
                    <div className="horoscope-section">
                        <h3>Personal Life (Vyaktigat Jeevan)</h3>
                        <p>{personalLife}</p>
                    </div>
                    <div className="horoscope-section">
                        <h3>Career (Vyavsaayik Jeevan)</h3>
                        <p>{career}</p>
                    </div>
                    <div className="horoscope-section">
                        <h3>Health (Swasthya)</h3>
                        <p>{health}</p>
                    </div>
                    <div className="horoscope-section">
                        <h3>Financial Condition (Aarthik Stithi)</h3>
                        <p>{finances}</p>
                    </div>
                    <div className="horoscope-section">
                        <h3>Advice (Saavdhaniyan aur Salah)</h3>
                        <p>{advice}</p>
                    </div>
                </>
            );
        } else if (currentOption === 'weekly') {
            const { weekRange, summary, personalLife, career, health, finances, advice } = horoscope;
            return (
                <>
                    <p className="date">Week: {weekRange}</p>
                    <div className="horoscope-section">
                        <h3>Summary</h3>
                        <p>{summary}</p>
                    </div>
                    <div className="horoscope-section">
                        <h3>Personal Life (Vyaktigat Jeevan)</h3>
                        <p>{personalLife}</p>
                    </div>
                    <div className="horoscope-section">
                        <h3>Career (Vyavsaayik Jeevan)</h3>
                        <p>{career}</p>
                    </div>
                    <div className="horoscope-section">
                        <h3>Health (Swasthya)</h3>
                        <p>{health}</p>
                    </div>
                    <div className="horoscope-section">
                        <h3>Financial Condition (Aarthik Stithi)</h3>
                        <p>{finances}</p>
                    </div>
                    <div className="horoscope-section">
                        <h3>Advice (Saavdhaniyan aur Salah)</h3>
                        <p>{advice}</p>
                    </div>
                </>
            );
        } else if (currentOption === 'monthly') {
            const { month, summary, personalLife, career, health, finances, advice } = horoscope;
            return (
                <>
                    <p className="date">Month: {month}</p>
                    <div className="horoscope-section">
                        <h3>Summary</h3>
                        <p>{summary}</p>
                    </div>
                    <div className="horoscope-section">
                        <h3>Personal Life (Vyaktigat Jeevan)</h3>
                        <p>{personalLife}</p>
                    </div>
                    <div className="horoscope-section">
                        <h3>Career (Vyavsaayik Jeevan)</h3>
                        <p>{career}</p>
                    </div>
                    <div className="horoscope-section">
                        <h3>Health (Swasthya)</h3>
                        <p>{health}</p>
                    </div>
                    <div className="horoscope-section">
                        <h3>Financial Condition (Aarthik Stithi)</h3>
                        <p>{finances}</p>
                    </div>
                    <div className="horoscope-section">
                        <h3>Advice (Saavdhaniyan aur Salah)</h3>
                        <p>{advice}</p>
                    </div>
                </>
            );
        }
    };
    
    return (
        <>
            <Nav />
            <div className="horoscope-container">
                <h1>Horoscope</h1>
                <h2>{sign}</h2>
                <div className="date-picker">
                    {/* <label htmlFor="date">Select a Date: </label> */}
                    {/* <input
                        type="date"
                        id="date"
                        className='date'
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                    /> */}
                </div>
                <div className="toggle-buttons">
                    <button
                        className={currentOption === 'daily' ? 'active' : ''}
                        onClick={() => setCurrentOption('daily')}
                    >
                        Daily
                    </button>
                    <button
                        className={currentOption === 'weekly' ? 'active' : ''}
                        onClick={() => setCurrentOption('weekly')}
                    >
                        Weekly
                    </button>
                    <button
                        className={currentOption === 'monthly' ? 'active' : ''}
                        onClick={() => setCurrentOption('monthly')}
                    >
                        Monthly
                    </button>
                </div>
                {renderHoroscopeContent()}
            </div>
            <div className="astrology-section">
                <div className="images">
                    <img
                    src="/astro.png" // Replace with your image URL
                    alt="Astrologer 1"
                    className="astro-img"
                    />
                </div>
                <div className="content">
                    <h2>Why wait?</h2>
                    <p>
                    Get your free astrology reading with vedic rashi in just 30
                    seconds. Unlock your vedic birth chart and start your journey with our
                    astrologer for free.
                    </p>
                    <h3>Talk to astrologer now.</h3>
                    <button onClick={redirectChat} className="get-started-btn">
                    Chat now <span className="arrow">→</span>
                    </button>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Horoscope;
