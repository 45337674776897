import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../firebase';
import { doc, setDoc, getDoc, arrayUnion, updateDoc } from 'firebase/firestore';
import './ChatInterface.css';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import appConfig from '../config/appConfig.json'
import Feedback from './Feedback';
import UserDetailsInterface from './UserDetailsInterface';

const sendSound = new Audio('/sounds/send-sound.mp3');
const receiveSound = new Audio('/sounds/receive-sound.mp3');

sendSound.volume = 0.4;   // 50% volume for send sound
receiveSound.volume = 0.3; // 70% volume for receive sound

const ChatInterface = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingContinueChat, setLoadingContinueChat] = useState(false);
  const [hasFetchedAstrologyData, setHasFetchedAstrologyData] = useState(false);
  const [userData, setUserData] = useState(null);
  const [chatCount, setChatCount] = useState(3);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isPlanExpired, setIsPlanExpired] = useState(false);
  const [showOptions, setShowOptions] = useState(true);
  const [showLimitMessage, setShowLimitMessage] = useState(false);
  const [user] = useAuthState(auth);
  const chatWindowRef = useRef(null);
  const inputRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);
  const [sessionId, setSessionId] = useState(() => uuidv4());
  const [chatCountForFeedback, setChatCountForFeedback] = useState(0);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [pendingMessage, setPendingMessage] = useState(''); // Store pending message
  const [canChat, setCanChat] = useState(true);
  const [pendingQuestionFromDetails, setPendingQuestionFromDetails] = useState(null);
  const [isQuestionProcessed, setIsQuestionProcessed] = useState(false);

  const playSendSound = () => {
    sendSound.play();
  };

  const playReceiveSound = () => {
    receiveSound.play();
  };
  const processingRef = useRef(false);
  const questionProcessedRef = useRef(new Set());

  // Simulate typing animation and then send a message
  const simulateTypingAndSendMessage = (messageText, isGreeting = false) => {
    // setIsTyping(true);
    
    setTimeout(() => {
      setIsTyping(false);
      
      const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const newMessage = {
        text: messageText,
        sender: 'bot',
        time: timestamp,
      };

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      // Reset typing state if it's not a greeting message
      if (!isGreeting) {
        setLoading(false);
      }
    }, 2000); // 2-second delay
  };

  // Function to send greeting message
  const sendGreetingMessage = (data) => {
    const greetingMessage = data;
    setIsTyping(true);
    simulateTypingAndSendMessage(greetingMessage, true);
  };

  const initialOptions = [
    'Tell me about my career.',
    'when will I find true love?',
    'When will I become rich?',
    'When will I get married?'
  ];
  // Handle when a user selects an option
  const handleOptionSelect = (option) => {
    setShowOptions(false); // Hide the options after selection
    // simulateTypingAndSendMessage("I’m analyzing your astrological chart to give your answer.");
    handleSendOption(option); // Send the selected option as a message
  };
  // Handle when a user selects an option
  const handleSendOption = async (option) => {
    setLoading(true);
    playSendSound();

    const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    if (chatCount <= 0 && !isSubscribed) {
        // If chat limit is reached, store the option as pending and show the subscription prompt
        setShowLimitMessage(true);
        setMessages((prevMessages) => [
            ...prevMessages,
            { text: 'You have reached your free chat limit. Please subscribe to continue.', sender: 'bot', time: timestamp },
        ]);
        setPendingMessage(option); // Store the selected option as pending
        setLoading(false);
        return;
    }

    // Add the selected option to the chat history
    setMessages((prevMessages) => [
        ...prevMessages,
        { text: option, sender: 'user', time: timestamp },
    ]);

    setChatCountForFeedback((prev) => prev + 1); // Increment chat count for feedback purposes

    // Show feedback popup if this is the 3rd chat
    if (chatCountForFeedback + 1 === 2 && !showFeedbackPopup) {
        setShowFeedbackPopup(true);
    }

    // Send the selected option to the API
    await sendMessageToAPI(option);

    // Decrement chat count and update Firestore only after response is received
    const updatedChatCount = chatCount - 1;
    setChatCount(updatedChatCount);

    await setDoc(doc(firestore, 'users', user.uid), {
        ...userData,
        RemainingchatCount: updatedChatCount,
    }, { merge: true });

    setLoading(false);
  };

  useEffect(() => {
    // Check if feedback has already been submitted
    const hasSubmittedFeedback = localStorage.getItem('feedbackSubmitted');
    if (hasSubmittedFeedback) {
      setFeedbackSubmitted(true);
    }
  }, []);

  useEffect(() => { 
    if (user) {
      // console.log(user)
      const fetchUserData = async () => {
            setIsLoading(true); 
            try {
              const response = await axios.get(`${appConfig.REACT_APP_BACKEND_URL}/users/${user.uid}`);
              const data = response.data;
              
              // Setting user data
              setUserData(data);
              if (data.pendingQuestion) {
                setPendingQuestionFromDetails(data.pendingQuestion);
              }
              // Set chat count with a default value if not defined
                setChatCount(data.RemainingchatCount !== undefined ? data.RemainingchatCount : 5);
                setIsSubscribed(data.isSubscribed || false);

                // Convert Firestore timestamp to date and check expiry
                let expiryDate = null;
                if (data.planExpiry && data.planExpiry._seconds) {
                    expiryDate = new Date(data.planExpiry._seconds * 1000);
                }

                // Check if the plan is expired
                if (expiryDate && expiryDate <= new Date()) {
                    setIsSubscribed(false); // Mark as not subscribed
                    setChatCount(0); // Reset chat count if plan is expired
                    setIsPlanExpired(true); // Set plan expired flag
                    await updateSubscriptionStatus(user.uid, false); // Update status in Firestore
                } else {
                    setIsPlanExpired(false); // Reset flag if the plan is valid
                }
            } catch (error) {
                console.error('Error fetching user data from frontend:', error);
            }
        };
        
        // Initiate data fetch and start 3-second loading timer
        const startLoading = async () => {
          const fetchPromise = fetchUserData();
          const delayPromise = new Promise((resolve) => setTimeout(resolve, 3000)); // 3-second delay
          await Promise.all([fetchPromise, delayPromise]);
          setIsLoading(false);
          sendGreetingMessage("Namaste! How can I assist you today with your astrological queries?"); 
        };

        startLoading();
      } 
  }, [user]);

  useEffect(() => {
    // Trigger feedback popup after three chats
    if (chatCountForFeedback === 3) {
      setShowFeedbackPopup(true);
    }
  }, [chatCountForFeedback]);

  useEffect(() => {
    if (!hasFetchedAstrologyData && userData) {
      fetchAstrologyDetails(userData);
      setHasFetchedAstrologyData(true);
    }
  }, [userData, hasFetchedAstrologyData]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input field when the component mounts
    }
  }, []);

  useEffect(() => {
    // On page load or refresh, set a new session ID
    const newSessionId = uuidv4();
    setSessionId(newSessionId);
    
    // Optionally, reset conversation summary on page refresh
    resetConversationSummary(newSessionId);
    
  }, []);

  useEffect(() => {
    // Logic to handle reset and new session ID when the page is refreshed
    window.addEventListener('beforeunload', () => {
      const newSessionId = uuidv4();
      setSessionId(newSessionId);
      resetConversationSummary(newSessionId);
    });
    
    return () => {
      window.removeEventListener('beforeunload', () => {});
    };
  }, []);

  useEffect(() => {
  // Guard against multiple executions
  if (!pendingQuestionFromDetails || isLoading || messages.length === 0) return;
  
  // Check if this specific question has already been processed
  if (questionProcessedRef.current.has(pendingQuestionFromDetails)) return;
  
  // Check if we're currently processing
  if (processingRef.current) return;
  
  // Set processing flag
  processingRef.current = true;
  
  const processQuestion = async () => {
    try {
      const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      
      // Add message only if it hasn't been added yet
      setMessages((prevMessages) => {
        // Check if message already exists
        const messageExists = prevMessages.some(
          msg => msg.text === pendingQuestionFromDetails && msg.sender === 'user'
        );
        
        playSendSound();
        if (messageExists) return prevMessages;
        
        return [
          ...prevMessages,
          { text: pendingQuestionFromDetails, sender: 'user', time: timestamp }
        ];
      });
      setShowOptions(false);
      // Process the pending question
      await sendMessageToAPI(pendingQuestionFromDetails);
      
      // Update chat count
      const updatedChatCount = chatCount - 1;
      setChatCount(updatedChatCount);
      setChatCountForFeedback(prev => prev + 1);

      // Update Firestore
      await setDoc(doc(firestore, 'users', user.uid), {
        ...userData,
        RemainingchatCount: updatedChatCount,
        pendingQuestion: null
      }, { merge: true });

      // Mark this specific question as processed
      questionProcessedRef.current.add(pendingQuestionFromDetails);
      
      // Clear pending question from state
      setPendingQuestionFromDetails(null);
    } catch (error) {
      console.error('Error processing pending question:', error);
    } finally {
      // Reset processing flag
      processingRef.current = false;
    }
  };

  // Use setTimeout to ensure greeting message is shown first
  const timer = setTimeout(processQuestion, 1000);

  return () => {
    clearTimeout(timer);
  };
  }, [pendingQuestionFromDetails, isLoading, messages.length]);
  
  useEffect(() => {
    return () => {
      questionProcessedRef.current.clear();
    };
  }, []);

  // useEffect(() => {
  //   if (pendingQuestionFromDetails) {
  //     setIsQuestionProcessed(false);
  //   }
  // }, [pendingQuestionFromDetails]);
  
  //Function to save summary in db
  
  const saveConversationSummary = async (userMessage, summary) => {
    try {
      const userRef = doc(firestore, 'users', user.uid);
      await updateDoc(userRef, {
        conversationHistory: arrayUnion({
          sessionId: sessionId,  // Save with session ID
          userMessage: userMessage,
          summary: summary,
          timestamp: new Date(),
        }),
        // Append summary to existing conversationSummary
        conversationSummary: arrayUnion(summary) // Append summaries
      });
    } catch (error) {
      console.error("Error saving conversation summary:", error);
    }
  };

  //Function to generate the summary
  const summarizeResponse = async (userInput, llmResponse) => {
    try {
      const response = await axios.post(`${appConfig.REACT_APP_BACKEND_URL}/chat/get-summary`, { userInput, llmResponse });
      return response.data.summary;
    } catch (error) {
      console.error("Error fetching summary:", error);
      return null;
    }
  };

  const resetConversationSummary = async (newSessionId, summary) => {
    try {
      // console.log("from reset function", summary)
      const userRef = doc(firestore, 'users', user.uid);
      await updateDoc(userRef, {
        conversationSummary: "",
        currentSessionId: newSessionId  // Store the new session ID in Firestore
      });
    } catch (error) {
      console.error("Error resetting conversation summary:", error);
    }
  };
  
  // Check if there's related context in the conversation history
  const checkForRelatedContext = async (userInput) => {
    const userRef = doc(firestore, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    
    if (userDoc.exists()) {
      const summaries = userDoc.data().conversationSummary || [];
      // Concatenate summaries to create ongoing context
      const concatenatedSummaries = summaries.join(' '); // Use all past summaries

      if (concatenatedSummaries) {
        return concatenatedSummaries; // Return all past summaries as context
      }
    }
    return null; // No context found
  };

  const fetchAstrologyDetails = async (userData) => {
    try {
      // Send user data to the backend for astrology API calls and Firestore update
      const response = await axios.post(`${appConfig.REACT_APP_BACKEND_URL}/api/astrology-details`, userData);
  
      if (response.status === 200) {
        const astroData = response.data;
  
        // Update the frontend state with the received astroData
        setUserData((prevUserData) => ({
          ...prevUserData,
          astro_data: astroData,
        }));
      }
    } catch (error) {
      console.error("Error fetching astrology details:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: 'Error fetching astrology details. Please try again.', sender: 'bot' },
      ]);
    }
  };

  const handleSend = async () => {
    if (input.trim() === '') return;

    const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    const userMessage = input.trim(); // Capture the actual user message

    // Hide options as soon as a message is sent
    setShowOptions(false); // Ensure options are hidden when sending a message

    setMessages((prevMessages) => [
        ...prevMessages,
        { text: userMessage, sender: 'user', time: timestamp },
    ]);
    setInput(''); // Clear the input field
    playSendSound();

    // Check if the plan is expired or chat limit reached
    if (isPlanExpired || (chatCount <= 0 && !isSubscribed)) {
        setShowLimitMessage(true); // Show the popup to buy a plan
        
        setPendingMessage(userMessage); // Store the message for later if they buy a plan
        return; // Don't send message to LLM yet
    }

    // Normal message sending logic if they have chats remaining
    setIsTyping(true);
    await new Promise(resolve => setTimeout(resolve, 2000));

    await sendMessageToAPI(userMessage); 
    
    const updatedChatCount = chatCount - 1;
    setChatCountForFeedback(prev => prev + 1); // Increment chat count for feedback
    setChatCount(updatedChatCount);

    if (chatCountForFeedback + 1 === 3 && !showFeedbackPopup) {
      setShowFeedbackPopup(true);
    }

    await setDoc(doc(firestore, 'users', user.uid), {
        ...userData,
        RemainingchatCount: updatedChatCount,
    }, { merge: true });

    setIsTyping(false);
  };


  const sendMessageToAPI = async (userMessage) => {
      setLoading(true);
      setIsTyping(true);

      const relatedContext = await checkForRelatedContext(userMessage);

      try {
          const response = await axios.post(
              `${appConfig.REACT_APP_BACKEND_URL}/chat/get-response`,
              { userMessage, userData, relatedContext }
          );

          const llmResponse = response.data.response;

          setMessages((prevMessages) => [
              ...prevMessages,
              { text: llmResponse, sender: 'bot' },
          ]);
          playReceiveSound();

          // Save conversation summary if needed
          const summary = await summarizeResponse(userMessage, llmResponse);
          if (summary) {
              await saveConversationSummary(userMessage, summary);
          }

      } catch (error) {
          setMessages((prevMessages) => [
              ...prevMessages,
              { text: 'Error fetching response. Please try again.', sender: 'bot' },
          ]);
          playReceiveSound();
      } finally {
          setIsTyping(false);
          setLoading(false);
      }
  };

  const handlePlanPurchase = async (planType, planId, duration, chats) => {
      try {
          const { data } = await axios.post(`${appConfig.REACT_APP_BACKEND_URL}/plan/purchase`, {
              planType,
              amount: planId,
              duration,
              chats,
              userId: user.uid,
          });

          const options = {
              amount: planId * 100,
              order_id: data.orderId,
              handler: async (response) => {
                  try {
                      await axios.post(`${appConfig.REACT_APP_BACKEND_URL}/plan/confirm`, {
                          userId: user.uid,
                          razorpay_payment_id: response.razorpay_payment_id,
                          planType,
                          duration,
                          chats,
                      });

                      // Update local chat count to purchased chats (e.g., 10)
                      setChatCount(chats);
                      await updateChatCountInFirestore(chats); // Update Firestore with the new chat count

                      setShowLimitMessage(false); // Close the pop-up
                      setCanChat(true); // Allow the user to chat again

                      // If there was a pending message, send it to the LLM
                      if (pendingMessage) {
                        await sendMessageToAPI(pendingMessage); // Send the pending message to the LLM
                        setPendingMessage(''); // Clear the pending message

                        // Decrement the chat count after sending the response
                        const updatedChatCount = chats - 1; // Assuming they got chats after purchasing
                        setChatCount(updatedChatCount);
                        await updateChatCountInFirestore(updatedChatCount); // Update Firestore with the new chat count
                    }

                  } catch (error) {
                      alert('Plan purchase failed. Please try again.');
                  }
              },
          };

          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
      } catch (error) {
          alert('Purchase initiation failed.');
      }
  };

  const updateChatCountInFirestore = async (updatedChatCount) => {
      await setDoc(doc(firestore, 'users', user.uid), {
          ...userData,
          RemainingchatCount: updatedChatCount,
      }, { merge: true });
  };

  const closeLimitMessage = () => {
    setShowLimitMessage(false);
    if (pendingMessage) {
      const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: 'You need to buy a plan to continue chatting.', sender: 'bot', time: timestamp },
      ]);
      setPendingMessage(''); // Clear the pending message after prompting
      setInput(""); // Hide the limit message
    }
  };

  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scroll({
        top: chatWindowRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const updateSubscriptionStatus = async (userId, status) => {
    await setDoc(doc(firestore, 'users', userId), {
      isSubscribed: status,
    }, { merge: true });
  };

  if (!user) {
    return (
      <UserDetailsInterface />
    );
  }
  if (isLoading) {
    return (
      <div className='loadin-page'>
        {/* Top Section */}
        <div className="top-section">
            <div className="top-section-content">
              <Link to="/" className='no-underline'><i class="ri-arrow-left-line"></i></Link>
              <div className="profile-pic-container">
                <img src="astro.png" alt="Astrologer" className="profile-pic" />
                <div className="status-dot"></div>  {/* Green online status dot */}
              </div>
              <div className="astrologer-info">
                <h4>Acharya Raghavendra</h4>
                <h5>Remaining Chats: {chatCount}</h5>
              </div>
            </div>
          </div>
        <div className="loading-page-container">
          <h2>Connecting with the stars...</h2>
          <p>Your personalized astrological experience is on its way!</p>
          <div className="blur-balls"></div>
          <video className='loading-video' src='https://cdnl.iconscout.com/lottie/premium/thumb/magic-circle-animated-icon-download-in-lottie-json-gif-static-svg-file-formats--occult-magical-sun-mystic-sign-astrology-pack-entertainment-icons-8542368.mp4' autoPlay loop muted></video>
        </div>
      </div>
      
    );
  }
  return (
    <div>
      <div className="chat-container">
        {/* Top Section */}
        <div className="top-section">
          <div className="top-section-content">
            <Link to="/" className='no-underline'><i class="ri-arrow-left-line"></i></Link>
            <div className="profile-pic-container">
              <img src="astro.png" alt="Astrologer" className="profile-pic" />
              <div className="status-dot"></div>  {/* Green online status dot */}
            </div>
            <div className="astrologer-info">
              <h4>Acharya Raghavendra</h4>
              <h5>Remaining Chats: {chatCount}</h5>
            </div>
          </div>
        </div>

        {/* Chat Window */}
        <div className="chat-window" ref={chatWindowRef}>
          {Array.isArray(messages) && messages.map((msg, index) => (
            <div key={index} className={`message ${msg.sender}`}>
              <div className="message-content">
                {msg.sender === 'bot' ? (
                  <ReactMarkdown>{msg.text}</ReactMarkdown>
                ) : (
                  msg.text
                )}
                {/* Render the timestamp for both user and bot */}
                <div className="timestamp">{msg.time}</div>
              </div>
            </div>
          ))}

          {/* Loading indicator */}
          {isTyping && (  
            <div className="typing-indicator">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          )}

          {/* Display options if showOptions is true */}
          {showOptions && (
            <div className="options-container">
              {initialOptions.map((option, index) => (
                <button key={index} className="option-button" onClick={() => handleOptionSelect(option)}>
                  {option}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Input Section */}
        <div className="input-container">
          <input
            ref={inputRef}
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Ask your question here..."
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
            // disabled={loading}
          />
          <button onClick={handleSend} disabled={loading}><i className="ri-send-plane-fill"></i></button>
        </div>

        {/* Subscription Modal */}
        {showLimitMessage && (
          <div className="subscription-popup">
          <div className="subscription-content">
            <span className="close-btn" onClick={closeLimitMessage}>&times;</span>
            
            <h1 className="subscription-heading">
              <i className="ri-bard-fill"></i> 
              Stay Connected with the Stars
              <i className="ri-bard-fill"></i>
            </h1>
            
            <p className="subscription-message">
              You have reached your chats limit. 
            </p>
            <h2>To continue, get <strong>10 more chats</strong> for just <strong>₹9</strong>.</h2>
            <img src='https://cdni.iconscout.com/illustration/premium/thumb/pandit-ji-reading-mantras-illustration-download-in-svg-png-gif-file-formats--male-indian-mantra-slok-pack-people-illustrations-2319299.png?f=webp' alt='pandit'></img>
            <button 
              onClick={() => handlePlanPurchase('Daily', '9', 1, 10)}
              className="subscription-button"
            >
              {loadingContinueChat ? <span className="spinner"></span> : 'Continue Chatting'}
            </button>
        
            <p className="subscription-link">
              Want more? <Link to="/pricing" className="pricing-link">Check our pricing plans</Link>
            </p>
          </div>
        </div>        
        )}
        {/* Chat UI */}
        <div>
          <Feedback
            userId={user.uid}
            userName={user.displayName}
            email={user.email}
            show={showFeedbackPopup}
            onClose={() => setShowFeedbackPopup(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;
